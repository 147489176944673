import React, { useEffect, useState } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavbarBottom from './Components/Sections/NavbarBottom';
import { Spin } from 'antd';
import './Assets/css/style.css'

import NotFound from './Components/Pages/NotFound'
import AccessDenied from './Components/Pages/AccessDenied'
import checkAuthorization from './Components/API/checkAuthorization'
import ProtectedRoute from './Components/Utils/ProtectedRoute'
import ClockIn from './Components/Pages/Clockin';
import DeviceCheck from './Components/Utils/DeviceCheck'
import UseMobile from './Components/Pages/UseMobile'



function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const telegram = window.Telegram.WebApp;
  const tg_chat_id = telegram.initDataUnsafe?.user?.id; //5591115278 раскомментируйте
  telegram.expand() 

  useEffect(() => {
    async function authorize(){
      const authResult = await checkAuthorization();
      setIsAuthorized(authResult);
      setIsLoading(false);
    }

    authorize();
  }, []);

  if (isLoading) {
    return (
      <div className="spin-style">
        <Spin spinning={isLoading} size="large" tip="Loading...">
          <div style={{ height: '100vh', width: '100vw' }} />
        </Spin>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
          <Routes>
            <Route path="/" element={<ProtectedRoute isAuthorized={isAuthorized}><NotFound /></ProtectedRoute>}/>
            <Route path="/clock-in" element={<ProtectedRoute isAuthorized={isAuthorized}><ClockIn /></ProtectedRoute>}/>
            <Route path="/timesheet" element={<ProtectedRoute isAuthorized={isAuthorized}><NotFound /></ProtectedRoute>}/>
            <Route path="/approvals" element={<ProtectedRoute isAuthorized={isAuthorized}><NotFound /></ProtectedRoute>}/>
            <Route path="/menu" element={<ProtectedRoute isAuthorized={isAuthorized}><NotFound /></ProtectedRoute>}/>
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/change-device" element={<UseMobile />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        {/* <DeviceCheck/> раскомментируйте */}   
          {isAuthorized && <NavbarBottom />}
      </div>
    </Router>
  );
}

export default App;
