import CreateUser from "./CreateUser";

export default async function checkAuthorization(){
    const telegram = window.Telegram.WebApp;
    const userChatId = telegram.initDataUnsafe?.user?.id; // 5591115278 раскомментируйте

    async function fetchUserByChatId(){
        try {
            //proxy request to check if user exist in bitrix
            const response = await fetch(process.env.REACT_APP_STRAPI_API_URL + `/get_user_by_tid?tid=${userChatId}`)
            const result = await response.json(); //text()
            await localStorage.setItem('bid', result[0])
            await localStorage.setItem('forceLocation', result[1])
            await CreateUser(userChatId, result[0])

            return result
        } catch (error) {
            console.error('Errir fetching user by chat ID: ', error);
            return 0;
        }
    }
    const user = await fetchUserByChatId();
    return user !== 0;
}