import axios from 'axios';
import { Modal } from 'antd';

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const retryRequest = async (requestFunction, retries = 3) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await requestFunction();
    } catch (error) {
      if (i === retries - 1) throw error;
    }
  }
};

export const handleClockIn = async ({
  isWithinZone,
  status,
  setStatus,
  position,
  tg_chat_id,
  forceLocation
}) => {
  let ableToLogin;

  if (forceLocation == 1 && isWithinZone) {
    ableToLogin = true;
  } else if (forceLocation !== 1) {
    ableToLogin = true;
  } else {
    ableToLogin = false;
  }

  const typeStrapiClockInOut = status ? 'clock-out' : 'clock-in';
  const typeBitrixClockInOut = status ? '6838' : '6837';
  const typeBoolean = status ? false : true;

  if (ableToLogin) {
    const currentDate = new Date();
    const currentTimeStripeFormat = currentDate.toISOString();
    const currentTimeBitrixFormat = formatDate(currentDate);
    const bid = localStorage.getItem("bid");
    const userTrackingID = localStorage.getItem("userTrackingID");
    const element_code = bid + '-' + Math.floor(Date.now() / 1000);

    try {

      // Искусственная ошибка для тестирования
      // await retryRequest(() => {
      //   throw new Error('Simulated Bitrix API error');
      // });
      
      // POST ClockInOut BITRIX
      await retryRequest(() => axios.post(process.env.REACT_APP_BITRIX_API_URL + `/lists.element.add`, {
        'IBLOCK_TYPE_ID': 'lists_socnet',
        'IBLOCK_ID': '187',
        'ELEMENT_CODE': `${element_code}`,
        'FIELDS': {
          'NAME': "name",
          'PROPERTY_910': currentTimeBitrixFormat,
          'PROPERTY_912': bid,
          'PROPERTY_913': position.toString(),
          'PROPERTY_915': typeBitrixClockInOut
        }
      }));

      // POST ClockInOut STRAPI (только если успешен запрос в Bitrix)
      await retryRequest(() => axios.post(process.env.REACT_APP_STRAPI_API_URL + `/clock-in-outs`, {
        "data": {
          "bitrix_id": bid,
          "telegram_chat_id": `${tg_chat_id}`,
          "type": typeStrapiClockInOut,
          "users_tracking": userTrackingID,
          "timestamp": currentTimeStripeFormat,
          "latitude": position[0],
          "longitude": position[1]
        }
      }, {
        headers: {
          Authorization: `Bearer ` + process.env.REACT_APP_API_TOKEN
        }
      }));

      // PUT usersTracking STRAPI (также только если успешны оба предыдущих запроса)
      await retryRequest(() => axios.put(process.env.REACT_APP_STRAPI_API_URL + `/users-trackings/${userTrackingID}`, {
        "data": {
          "active": typeBoolean
        }
      }, {
        headers: {
          Authorization: `Bearer ` + process.env.REACT_APP_API_TOKEN
        }
      }));

      setStatus(typeBoolean);

      // Показываем успех только после успешного завершения всех операций
      Modal.success({
        title: 'Success',
        content: `${typeStrapiClockInOut} successful!`,
        className: 'custom-success-modal',
      });

      return {
        attributes: {
          type: typeStrapiClockInOut,
          timestamp: currentTimeStripeFormat
        }
      };
    } catch (error) {
      console.error('An error occurred:', error);
      Modal.error({
        title: 'Error',
        content: `An error occurred during ${typeStrapiClockInOut}. Please try again.`,
        className: 'custom-error-modal',
      });
    }
  } else {
    Modal.error({
      title: 'Error',
      content: 'You are not in Office.',
      className: 'custom-error-modal',
    });
  }
};
